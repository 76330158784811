<p-dialog [header]="(esExtender ? 'Extender' : 'Clonar') + ' Proyecto'" [modal]="true" [(visible)]="esVisible" (onHide)="cancelar()" [style]="{ width: '30rem' }">
    <div class="p-field mt-3 mb-4" *ngIf="!esExtender">
      <span class="p-float-label">
        <input id="nuevoNombre" type="text" [(ngModel)]="nuevoNombre" pInputText style="width: 100%;" />
        <label for="nuevoNombre">Nombre del proyecto</label>
      </span>
    </div>
    <div class="p-field mt-3 mb-5" *ngIf="esExtender">
        <span class="p-float-label">
          <p-dropdown [className]="'p-fluid flex-1'" [options]="obtieneFechas()"
                [(ngModel)]="ejercicio" 
              placeholder="* Selecciona la fecha" pTooltip="* Selecciona la fecha" class="ml-5 mr-3">
          </p-dropdown>

          <label for="ejercicio">Ejercicio</label>
        </span>
    </div>
    <div class="flex flex-row p-fluid mb-1 mt-3">
      <div class="field p-field w-8">
        <span class="p-float-label">
          <p-calendar 
              [(ngModel)]="fechasInicioFin" 
              selectionMode="range"
              appendTo="body" #fecha [firstDayOfWeek]="1"
              [iconDisplay]="'input'"
              [showIcon]="true" placeholder="Fecha Inicio - Fecha Fin" dateFormat="dd/mm/yy"/>
          <label for="mes">Fecha Inicio - Fecha Fin</label>
        </span> 
      </div>
      <small class="p-error" style="margin-top: 42px;" *ngIf="(fechasInicioFin && (!fechasInicioFin[0] || !fechasInicioFin[1]))">Por favor, seleccione ambas fechas en el rango.</small> 
    </div>
    <div class="flex flex-row mt-3">
      <div class="field p-field w-9">
        <span class="p-float-label">
          <span style="font-family: Roboto; font-size: 14px; font-weight: 600; line-height: 24px;">Selecciona lo que quieras clonar:</span>
        </span>  
      </div>
    </div>
    <div class="flex flex-row gap-1 mb-1 mt-1">
      <div class="field p-field w-6">
        <p-checkbox [(ngModel)]="integrantes" (ngModelChange)="onIntegrantesChange($event)" binary="true" label="Integrantes"></p-checkbox>
      </div>
      <!-- <div class="field p-field w-6">
        <p-checkbox [(ngModel)]="horasPlanificadas" [disabled]="!integrantes" binary="true" label="Horas Planificadas"></p-checkbox>
      </div> -->
      <div class="field p-field w-6">
        <p-checkbox [(ngModel)]="periodosProgramacion" binary="true" label="Periodos de Programación"></p-checkbox>
      </div>
    </div>
    <div class="flex flex-row gap-1 mb-1 mt-1">
      <div class="field p-field w-6">
        <p-checkbox [(ngModel)]="delegados" binary="true" label="Delegados"></p-checkbox>
      </div>
      <div class="field p-field w-6">
        <p-checkbox [(ngModel)]="estructuraEconomica" binary="true" label="Estructura Económica"></p-checkbox>
      </div>
    </div>
    <div class="flex flex-row gap-1 mb-1 mt-1">
      <div class="field p-field w-6">
        <p-checkbox [(ngModel)]="tareas" binary="true" label="Tareas"></p-checkbox>
      </div>
      <div class="field p-field w-6">
        <p-checkbox [(ngModel)]="conceptosSalariales" binary="true" label="Conceptos Salariales"></p-checkbox>
      </div>
    </div>
    
    <p-footer>
      <p-button label="Cancelar" icon="pi pi-times" class="mr-2" severity="secondary" (click)="cancelar()" />
      <p-button icon="pi pi-save" label="Guardar" severity="success" (click)="duplicarProyectoSeleccionado()" [disabled]="!(nuevoNombre || ejercicio) || (fechasInicioFin && (!fechasInicioFin[0] || !fechasInicioFin[1])) 
                                                                                                                    || (esExtender && (!fechasInicioFin  || !fechasInicioFin![0] || !fechasInicioFin![1]))" />
    </p-footer>
  </p-dialog>
  