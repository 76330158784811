import { Component, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TIPO_DOC_OPERACIONAL } from 'src/app/common/constantes';
import { FileWithType } from 'src/app/model/FileWithType';
import { Proyecto } from 'src/app/model/proyecto';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SubidaFicherosComponent } from '../subida-ficheros/subida-ficheros.component';
import { FicheroService } from 'src/app/services/ficheros.service';
import { MessagesService } from 'src/app/services/message.service';

@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrl: './dialog-upload.component.scss'
})
export class DialogUploadComponent {

  proyecto!: Proyecto;
  carpeta!: any;
  filesWithTypes: FileWithType[] = [];
  TIPO_DOC_OPERACIONAL = TIPO_DOC_OPERACIONAL;
  loading = false;
  @ViewChild("subida") subida!: SubidaFicherosComponent;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, public spinnerService: SpinnerService, 
    public ficheroService: FicheroService, private messageService: MessagesService) {

    this.spinnerService.isLoading.subscribe(bool => this.loading = bool);
    
    this.config.data.forEach((property:any) => {
        this.proyecto = property.proyecto;
        this.carpeta = property.carpeta;
      }
    )
  }

  ngOnDestroy(): void {
    // no se envian los documentos que no se haya definido el tipo, porque no se subieron
    this.ref.close(this.filesWithTypes.filter(file => file.id != undefined));
  }

  ficheroConTipo(): boolean {
    return this.filesWithTypes.length > 0 && this.filesWithTypes.every( f => f.tipo.id !== 0);
  }

  submit() {
    this.filesWithTypes.forEach((file: FileWithType, index: number) => {
        this.ficheroService.upload(file, this.proyecto).subscribe({
          next: (response) => {
              this.filesWithTypes.find(f => f === file)!.id = response.id;
              this.filesWithTypes.find(f => f === file)!.webUrl = response.webUrl;

              this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Fichero subido correctamente' });

              //si es el ultimo cierro el dialogo
              if (index === this.filesWithTypes.length - 1) {
                this.ref.close();
              }
          },
          error: (e) => this.messageService.setMensaje({ severity: 'error', summary: 'Error', detail: 'Error al subir el fichero' })
      });
    });
  }
}
