import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OpcionesExtension } from 'src/app/model/opcionesExtension';

@Component({
    selector: 'app-dialog-extension-clonacion',
    templateUrl: './dialog-extension-clonacion.component.html',
    styleUrls: ['./dialog-extension-clonacion.component.scss']
})
export class DuplicarProyectoDialogComponent {

    nuevoNombre: string | undefined;
    ejercicio: string | undefined;
    fechasInicioFin: Date[] | undefined;
    integrantes: boolean = true;
    horasPlanificadas: boolean = false;
    delegados: boolean = true;
    estructuraEconomica: boolean = true;
    tareas: boolean = true;
    conceptosSalariales: boolean = true;
    periodosProgramacion: boolean = true;

    @Input() esVisible: boolean = false;
    @Input() esExtender: boolean = false;
    @Input() proyectoSeleccionado: any;

    // Evento para emitir cuando se guarda la duplicación
    @Output() duplicarProyecto = new EventEmitter<any>();

    constructor() { }

    duplicarProyectoSeleccionado() {
        const proyectoDuplicado: OpcionesExtension = {
            idOrigen: this.proyectoSeleccionado.id,
            nombreDuplicado: this.nuevoNombre,
            ejercicio: this.ejercicio,
            fechaIniFin: this.fechasInicioFin,
            integrantes: this.integrantes,
            horasPlanificadas: this.horasPlanificadas,
            delegados: this.delegados,
            estructuraEconomica: this.estructuraEconomica,
            tareas: this.tareas,
            conceptosSalariales: this.conceptosSalariales,
            periodosProgramacion: this.periodosProgramacion
        };

        this.duplicarProyecto.emit(proyectoDuplicado);
    }

    // Método para cambiar el estado de los checkboxes
    onIntegrantesChange(event: any) {
        if (!event) {
            this.horasPlanificadas = false;
        }
    }

    cancelar() {
        this.duplicarProyecto.emit(false);
        this.esVisible = false;
        this.nuevoNombre = undefined;
        this.fechasInicioFin = undefined;
    }

    obtieneFechas(): number[] {
        const anioActual = new Date().getFullYear();
        return [anioActual - 1, anioActual, anioActual + 1];
    }
}
