<div class="flex flex-column gap-3 mb-3 w-">
    <p-fileUpload 
        name="filesWithTypes[]" 
        [customUpload]="true" 
        multiple="true"
        [auto]="true"
        [showUploadButton]="false"
        (onSelect)="onSelect($event)" 
        #fileUpload>
        <ng-template pTemplate="content">
                <ng-container *ngFor="let fileWithType  of filesWithTypes; let i = index">
                    <div class="flex-grid">
                        <div class="flex flex-column overflow-hidden w-25rem">
                            <span>{{ fileWithType.file.name }}</span>
                        </div>
                        <div class="flex flex-column w-18rem">
                            <p-dropdown [className]="'p-fluid flex-1'" [options]="fileTypes" optionLabel="nombre" optionValue="id" [(ngModel)]="fileWithType.tipo.id" (onChange)="(this.proyecto.id != undefined && autoSubida) ? onUpload(fileWithType) : null"
                                placeholder="* Selecciona el tipo de archivo" pTooltip="* Selecciona el tipo de archivo" class="ml-5 mr-3">
                            </p-dropdown>
                        </div>
                        <div class="flex flex-column w-5rem">
                            <div class="flex flex-row">
                                <i tooltipposition="bottom" (click)="removeFile(i, fileWithType)" class="pi pi-trash ml-5 eliminar"
                                    pTooltip="Borrar"></i>
                                <i tooltipposition="bottom" *ngIf="fileWithType.id" (click)="downloadFile(fileWithType)"
                                    class="pi pi-download ml-3 descargar" pTooltip="Descargar"></i>
                            </div>
                            
                        </div>
                    </div>
                </ng-container>
                <div class="flex align-items-center justify-content-center flex-column">
                    <i class="pi pi-cloud-upload text-8xl text-400 border-400"
                        style="font-size: 60px !important; color: rgb(132, 175, 224) !important;"></i>
                    <p class="mt-1 mb-0">Arrastra y suelta archivos aquí para subirlos.</p>
                </div>  
        </ng-template>
        <ng-template pTemplate="footer"/>
    </p-fileUpload>
</div>